@tailwind base;
@tailwind components;
@tailwind utilities;




/* Tailwind CSS */
/* @layer utilities {
  .gradient-bg {
    height: 100vh;
    background: linear-gradient(to bottom, #000 50vh, black 50vh, black);
  }
} */

@layer utilities {
  .font-comfortaa {
    font-family: 'Comfortaa';
  }
  .font-poppins {
    font-family: 'Poppins';
  }
  .font-space {
    font-family: 'monospace';
  }
}


  :root {
    --foreground-rgb: 255, 255, 255;
    --background-initial-rgb: 0, 0, 0;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      #000, #000
  )
}

body::-webkit-scrollbar {
  width: 0;
}

/* Optional: Customize scrollbar track and handle appearance */
body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}
